export default slide = (useCase) => {
	return {
		title:  __("Calendrier de réservation"),
		content: `<div style="font-size: var(--text-lg);">
			<div>
				<img src="/assets/demo/images/pexels-olya-kobruseva-5386754.jpg">
			</div>
			<div class="mt-4">
				<p>Depuis son calendrier, Thierry peut voir ses espaces réservés.</p>
				<p>Il peut changer sa vue de calendrier pour ne regarder que les réservations de la semaine.</p>
				<p>Il peut également filtrer sur un article en particulier</p>
				<p>Enfin, il peut basculer en "vue Liste" pour voir plus facilement toutes ses réservations en attente de confirmation</p>
		</div>`,
		primary_action_label: "Explorer le calendrier",
		primary_action: () => {
			demo.ui.TourDialog.hide()
			setTimeout(() => {
				const tour = new demo.ui.Tour({ slides: "item-booking" });
				tour.show();
			}, 1000)
		}
	}
}

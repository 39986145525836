class demoListView extends frappe.views.ListView {
	before_refresh() {
		if (frappe.route_options?.show_tour) {
			frappe.create_routes[this.doctype] = ["Form", this.doctype, frappe.model.get_new_name(this.doctype)]
			frappe.new_doc(this.doctype).then(r => {
				cur_frm.toolbar.show_tour()
			})
			frappe.route_options = {}
			return super.before_refresh()
		} else {
			return super.before_refresh()
		}
	}
}

$(document).ready(() => {
	frappe.views.ListView = demoListView
})
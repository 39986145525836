export default slide = (useCase) => {
	return {
		title:  __("Achat"),
		content: useCase == "MappeMonde" ? `<div style="font-size: var(--text-lg);">
			<div>
				<img src="/assets/demo/images/pexels-erik-scheel-95425_selling.jpg"
				style="max-width:70%;height:auto;display:block;margin-left:auto;margin-right:auto">
			</div>
			<div class="mt-4">
				<p>${__("La saisie des achats est essentielle pour toute société souhaitant faire sa comptabilité avec dokos ou simplement suivre sa trésorerie.")}</p>
				<p>${__("Le module des achats contient tout les types de document et rapports utiles pour cela.")}</p>
				<p>${__("Thierry a acheté du café pour la cuisine de son coworking et souhaite saisir son achat.")}</p>
			</div>
		</div>` : `<div style="font-size: var(--text-lg);">
			<div>
				<img src="/assets/demo/images/pexels-erik-scheel-95425_selling.jpg"
				style="max-width:70%;height:auto;display:block;margin-left:auto;margin-right:auto">
			</div>
			<div class="mt-4">
				<p>${__("La saisie des achats est essentielle pour toute société souhaitant faire sa comptabilité avec dokos ou simplement suivre sa trésorerie.")}</p>
				<p>${__("Le module des achats contient tout les types de document et rapports utiles pour cela.")}</p>
				<p>${__("Thierry a fait une commande de télécommandes pour la dokoboard aupprès d'un fournisseur et souhaite saisir son achat.")}</p>
			</div>
		</div>`,
		primary_action_label: "Créer une nouvelle facture d'achat",
		primary_action: () => {
			demo.ui.TourDialog.hide()
			setTimeout(() => {
				frappe.set_route("Form", "Purchase Invoice", "new-purchase-invoice-1")
			}, 1000)
		},
		secondary_action_label: "Comment accéder aux factures d'achat ?",
		secondary_action: () => {
			demo.ui.TourDialog.hide()
			setTimeout(() => {
				const tour = new demo.ui.Tour({ slides: "buying_highlight" });
				tour.show();
			}, 1000)
		}
	}
}

export default buying_highlight = [
	{
		element: ".main-section > header > div > div > form > div > div",
		popover: {
			title: "En utilisant la barre de recherche",
			description: `
				<p>tapez "facture d'achat".</p>
				<p>L'une des suggestions est 'Nouveau.elle facture d'achat'</p>
			`,
			position: 'left-top',
		}
	},
	{
		element: "div.ce-block:nth-child(12) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) > a:nth-child(3) > span:nth-child(2)",
		popover: {
			title: "Par la liste des factures",
			description: `
			`,
			position: 'left-top',
		}
	},
]

import workspaces from './common/workspaces'
import custom_workspace_highlight from './common/custom_workspace_highlight'
import sales_invoice_highlight from './common/sales_invoice_highlight'
import purchase_invoice_highlight from './common/purchase_invoice_highlight'
import buying_highlight from './common/buying_highlight'
import item_booking from './mappemonde/item-booking'


/*
Enregistrez les éléments à mettre en évidence dans le dossier common ou dans le dossier correspondant au cas d'usage.
Puis enregistrez les dans ce fichier.
Il est ensuite possible d'appeler n'importe quel tour en lançant:

const tour = new demo.ui.Tour({ slides, on_finish });
tour.show();
*/

const data = (slides, useCase) => {
	const tours = {
		workspaces: workspaces,
		'custom_workspace_highlight': custom_workspace_highlight,
		'item-booking': item_booking,
		'sales_invoice_highlight': sales_invoice_highlight,
		'purchase_invoice_highlight': purchase_invoice_highlight(useCase),
		'buying_highlight': buying_highlight
	}
	return tours[slides] || []
}


export default data;

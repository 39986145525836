export default workspaces = [
	{
		element: ".desk-sidebar",
		popover: {
			title: "Navigation centrale",
			description: `
				<p>La barre de navigation permet de se déplacer d'un espace de travail à un autre</p>
				<p>Chaque espace de travail propose un certain de nombre de fonctionnalités, regroupées en fonction de leur pertinence</p>`,
			position: 'right-center',
		}
	},
	{
		element: ".layout-main-section",
		popover: {
			title: 'Les espaces de travail',
			description: `
				<p>Un espace de travail est une page regroupant des indicateurs et des raccourcis associés à un groupe de fonctionnalités cohérent.</p>
				<p>Chaque espace de travail est composé de graphiques, de raccourcis et de cartes contenant des liens vers un ensemble de fonctionnalité liées.</p>
			`,
			position: 'mid-center',
		}
	},
	{
		element: ".dashboard-widget-box",
		popover: {
			title: 'Les graphiques',
			description: `
				<p>Les graphiques permettent d'afficher rapidement des indicateurs pertinents pour comprendre l'état de l'activité de l'entreprise.</p>
				<p>Les graphiques peuvent être basés directement sur les données de certains types de documents ou sur des rapports effectuant des calculs additionnels.</p>
				<p>Vous pouvez modifier les filtres de certains graphiques pour changer vos horizon d'analyse.</p>
			`,
			position: 'bottom-center',
		}
	},
	{
		element: ".shortcut-widget-box",
		popover: {
			title: 'Les raccourcis',
			description: `
				<p>Les raccourcis permettent d'accéder rapidement à une fonctionnalité du logiciel.</p>
				<p>En plus d'être mis en avant sur votre espace de travail, ils permettent également de filtrer automatiquement les documents cibles.</p>
				<p>Quand un filtre est associé au raccourci, une vignette de couleur vous indique le nombre de documents filtrés que vous verrez quand vous cliquerez sur le raccourci.</p>
				<p>C'est très utile pour savoir si l'on a des actions en attente par exemple.</p>
			`,
			position: 'bottom-center',
		}
	},
	{
		element: ".links-widget-box",
		popover: {
			title: 'Les cartes de liens',
			description: `
				<p>Ces cartes regroupent des types de documents liés entre eux et regroupés de manière cohérente.</p>
				<p>Elles ont vocation à proposer une liste exhaustive des fonctionnalités disponibles pour les utilisateurs de Dokos.</p>
				<p>Les listes s'adaptent également en fonction des rôles et autorisations donnés à chaque utilisateur.</p>
			`,
			position: 'top-center',
		}
	}
]

export default slide = (useCase) => {
	return {
		title:  __("Gestion du lieu"),
		content: `<div style="font-size: var(--text-lg);">
			<div>
				<img src="/assets/demo/images/coworking.jpg"
					style="max-width:80%;height:auto;display:block;margin-left:auto;margin-right:auto">
			</div>
			<div class="mt-4">
				<p>${__("Thierry pilote son activité de locations d'espaces.")}</p>
				<p>${__("Ses clients ont différents profils:")}</p>
				<ul>
					<li>${__("Abonnés mensuels avec un droit d'accès illimité")}</li>
					<li>${__("Usagers ponctuels qui réservent en ligne via le site web de Dokos (Connectez-vous en tant qu'utilisateur pour tester le flux de réservation en ligne.)")}</li>
					<li>${__("Usagers ponctuels ayant prépayés un certain nombre de crédits leur donnant accès à l'espace de coworking")}</li>
				</ul>
			</div>
			<p>${__("Thierry souhaite commencer sa journée en faisant un tour sur les réservations du jour.")}</p>
		</div>`,
		primary_action_label: "Voir les réservations",
		primary_action: () => {
			demo.ui.TourDialog.hide()
			setTimeout(() => {
				frappe.set_route("List", "Item Booking", "Calendar")
			}, 1000)
		}
	}
}

export default slide = (useCase) => {
	return {
		title:  __("Welcome in Dokos"),
		content: useCase == "MappeMonde" ? `<div style="font-size: var(--text-lg);">
			<div>
				<img src="/assets/demo/images/hero-background.jpg">
			</div>
			<div class="mt-4">
				<p>${__("Bonjour,")}</p>
				<p>${__("Voici Thierry, gestionnaire du tiers lieu Mappemonde.")}</p>
				<p>${__("Mappemonde est une petite entreprise proposant des locations d'espace de travail et de salles de réunion.")}</p>
				<p>${__("Il utilise Dokos pour maîtriser toute la gestion, de la réservation de créneaux en ligne, au rapprochement bancaire.")}</p>
				<p>${__("Dans cette démo vous pouvez suivre l'activité quotidienne de Thierry ou bien découvrir directement la page d'accueil et les espaces de travail de Dokos.")}</p>
			</div>
		</div>` : `<div style="font-size: var(--text-lg);">
			<div>
				<img src="/assets/demo/images/hero-background.jpg">
			</div>
			<div class="mt-4">
				<p>${__("Bonjour,")}</p>
				<p>${__("Voici Thierry, gestionnaire de la société Dokompany.")}</p>
				<p>${__("Dokompany est une petite entreprise qui produit et commercialise un skateboard connecté : la Dokoboard.")}</p>
				<p>${__("Il utilise Dokos pour maîtriser toute la gestion, de la production du produit au rapprochement bancaire.")}</p>
				<p>${__("Dans cette démo vous pouvez suivre l'activité quotidienne de Thierry ou bien découvrir directement la page d'accueil et les espaces de travail de Dokos.")}</p>
			</div>
		</div>`,
		primary_action_label: "Suivre Thierry",
		primary_action: () => {
			demo.ui.TourDialog.hide()

			if (useCase == "MappeMonde") {
				setTimeout(() => {
					if (frappe.get_route().includes("Venue")) {
						set_tour()
					} else {
						frappe.set_route("venue")
					}
				}, 1000)
			} else {
				// default tour instead
				demo.ui.TourDialog.secondary_action();
			}
		},
		secondary_action_label: "Découvrir les espaces de travail de Dokos",
		secondary_action: () => {
			demo.ui.TourDialog.hide()
			setTimeout(() => {
				const tour = new demo.ui.Tour({ slides: "workspaces" });
				tour.show();
			}, 1000)
		}
	}
}

import data from "./highlights/index.js"
frappe.provide("demo.ui")


// Curent use cases are MappeMonde / Dokompany
const useCase = frappe.defaults.get_user_default("Company")
console.log("useCase in tour.js :", useCase)

demo.ui.Tour = class Tour {
	constructor({ slides, on_finish }) {
		this.driver_steps = [];
		this.slides = slides;
		this.on_finish = on_finish;
	}

	show() {
		this.init().then(() => {
			this.start();
		})
	}

	async init() {
		this.init_driver();
		this.build_steps();
		this.update_driver_steps();
	}

	init_driver() {
		this.driver = new frappe.Driver({
			className: 'frappe-driver',
			allowClose: true,
			padding: 10,
			overlayClickNext: true,
			keyboardControl: true,
			nextBtnText: __('Next'),
			prevBtnText: __('Previous'),
			doneBtnText: __('Done'),
			closeBtnText: __('Close'),
			opacity: 0.25,
		});

		frappe.router.on('change', () => this.driver.reset());
	}

	build_steps() {
		const steps = data(this.slides, useCase);

		if (steps.length && this.on_finish) {
			steps[steps.length - 1].onNext = () => {
				if (!this.driver.hasNextStep()) {
					setTimeout(() => {
						this.on_finish && this.on_finish();
					}, 1000)
				} else {
					this.driver.moveNext();
				}
			};
		}

		this.driver_steps = steps
	}

	update_driver_steps(steps = []) {
		if (steps.length == 0) {
			steps = this.driver_steps;
		}
		this.driver.defineSteps(steps);
	}

	start() {
		if (this.driver_steps.length == 0) {
			return;
		}

		this.driver.start();
	}
}
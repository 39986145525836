export default sales_invoice_highlight = [
	{
		element: '[data-page-route="List/Sales Invoice/List"] .standard-actions',
		popover: {
			title: "Un menu et des actions sont à votre disposition",
			description: `
				<p>Le menu vous permet de créer une nouvelle facture, de faire une annulation etc.</p>
			`,
			position: 'left-top',
		}
	},
    {
		element: '[data-page-route="List/Sales Invoice/List"] .standard-filter-section > [data-fieldname="status"]',
		popover: {
			title: "Filtrer par statut",
			description: `
				<p>Il est possible de filtrer les documents par statut. Par exemple, pour avoir une vue des factures impayées, il est possible d'afficher uniquement les factures ayant le statut 'impayé' ou 'en retard'</p>
			`,
			position: 'left-top',
		}
	},
	{
		element: '[data-page-route="List/Sales Invoice/List"] .list-row .indicator-pill.green',
		popover: {
			title: "Astuce",
			description: `
				<p>Vous pouvez aussi cliquer directement sur un statut pour l'utiliser comme filtre</p>
			`,
			position: 'right-center',
		}
	},
]

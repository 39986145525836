export default slide = (useCase) => {
	return {
		title:  __("Nouvelle facture d'achat"),
		content: `<div style="font-size: var(--text-lg);">
			<div>
				<img src="/assets/demo/images/pexels-kindel-media-7651555_sales-invoice.jpg"
					style="max-width:70%;height:auto;display:block;margin-left:auto;margin-right:auto">
			</div>
			<div class="mt-4">
				<p>Thierry a ouvert un nouveau brouillon de facture d'achat pour effectuer la saisie.</p>
				<p>Si le fournisseur et l'article sont déjà enregistrés et que le système est bien paramétré, la saisie peut se faire <strong>en 3 étapes</strong></p>
		</div>`,
		primary_action_label: "Saisir un achat",
		primary_action: () => {
			demo.ui.TourDialog.hide()
			setTimeout(() => {
				const tour = new demo.ui.Tour({ slides: "purchase_invoice_highlight", useCase: useCase });
				tour.show();
			}, 1000)
		}
	}
}


export default slide = (useCase) => {
	return {
		title:  __("Espaces de travail personnalisable"),
		content: useCase == "MappeMonde" ? `<div style="font-size: var(--text-lg);">
			<div>
				<img src="/assets/demo/images/pexels-tima-miroshnichenko-tailormade.jpg"
					style="max-width:70%;height:auto;display:block;margin-left:auto;margin-right:auto">
			</div>
			<div class="mt-4">
				<p>${__("La version 3 de dokos permet la création d'espace de travail entièrement personnalisable.")}</p>
				<p>${__("Thierry consulte son propre espace de travail pour avoir une vue sur mesure de ce qu'il se passe à Mappemonde.")}</p>
				<p>${__("Graphiques, listes rapides, raccourcis sur-mesure et autres lui permettent de se créer un espace parfaitement adapté")}</p>
			</div>
		</div>` : `<div style="font-size: var(--text-lg);">
			<div>
				<img src="/assets/demo/images/pexels-tima-miroshnichenko-tailormade.jpg"
					style="max-width:70%;height:auto;display:block;margin-left:auto;margin-right:auto">
			</div>
			<div class="mt-4">
				<p>${__("Thierry consulte son propre espace de travail pour avoir une vue sur mesure de dokompany.")}</p>
				<p>${__("La version 3 de dokos permet la création d'espace de travail entièrement personnalisable.")}</p>
				<p>${__("Graphiques, listes rapides, raccourcis sur-mesure et autres permettent à Thierry de se créer un espace parfaitement adapté")}</p>
			</div>
		</div>`,
		primary_action_label: "Explorer l'espace de travail de Thierry",
		primary_action: () => {
			demo.ui.TourDialog.hide()
			setTimeout(() => {
				const tour = new demo.ui.Tour({ slides: "custom_workspace_highlight" });
				tour.show();
			}, 1000)
		},
	}
}

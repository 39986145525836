export default slide = (useCase) => {
	return {
		title:  __("Module de vente"),
		content: `<div style="font-size: var(--text-lg);">
			<div>
				<img src="/assets/demo/images/pexels-erik-scheel-95425_selling.jpg"
					style="max-width:70%;height:auto;display:block;margin-left:auto;margin-right:auto">
			</div>
			<div class="mt-4">
				<p>Le module de vente est central à la gestion d'une organisation.</p>
				<p>En effet, il permet d'accéder à des rapports et type de documents essentiels : devis, commandes client, articles, factures de vente etc.</p>
				<p>Pour accéder à un type de document particulier, vous pouvez soit le sélectionner dans la liste des données de base, sélectionner le raccourci ou encore taper le nom du document dans la barre de recherche</p>
		</div>`,
		primary_action_label: "Consulter les factures de vente",
		primary_action: () => {
			demo.ui.TourDialog.hide()
			setTimeout(() => {
				frappe.set_route("List", "Sales Invoice")
			}, 1000)
		}
	}
}

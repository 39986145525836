export default purchase_invoice_highlight = (useCase) => {
	switch (useCase) {
		case 'MappeMonde':highlights = [
			{
				element: " .awesomplete > [data-fieldname='supplier']",
				popover: {
					title: "1.Saisir le fournisseur",
					description: `
						<p>Par exemple, tapez "café gringo".</p>
					`,
					position: 'left-top',
				}
			},
			{
				element: ".row.layout-main > div.col.layout-main-section-wrapper > div.layout-main-section > div:nth-child(2) > div > div > div.form-page > div:nth-child(9) > div > div > form > div:nth-child(3) > div.form-grid > div.grid-body > div.rows > div > div > div:nth-child(3)",
				popover: {
					title: "2.Saisir l'article...",
					description: `
						<p>Par exemple, tapez "café moulu".</p>
					`,
					position: 'left-top',
				}
			},
			{
				element: ".row.layout-main > div.col.layout-main-section-wrapper > div.layout-main-section > div:nth-child(2) > div > div > div.form-page > div:nth-child(9) > div > div > form > div:nth-child(3) > div.form-grid > div.grid-body > div.rows > div > div > div:nth-child(4)",
				popover: {
					title: "3. ...et la quantité",
					description: `
						<p>Verifiez que le prix unitaire est correcte</p>
					`,
					position: 'left-top',
				}
			},
			{
				element: ".row.layout-main > div.col.layout-main-section-wrapper > div.layout-main-section > div:nth-child(2) > div > div > div.form-page > div:nth-child(14)",
				popover: {
					title: "Vérifiez que la TVA est correcte",
					description: `
						<p>Sur ce site, un taux de 20% est appliqué aux achats par défaut. La TVA est entièrement paramètrable et le taux peut être appliqué selon le fournisseur, l'article. Elle peut être entièrement automatisé via des règles de taxation</p>
					`,
					position: 'left-top',
				}
			},
			{
				element: ".standard-actions.flex > button.btn.btn-primary.btn-sm.primary-action",
				popover: {
					title: "Enregistrez votre saisie",
					description: `
						<p>Avec le raccourci ctrl+s ou en cliquant sur 'enregistrer' dans le coin supérieur droit</p>
						<p>La facture s'enregistre en brouillon. Vous aurez ensuite la possibilité de la valider.</p>
					`,
					position: 'left-top',
				}
			},
		]
			break;
		default: highlights = [
			{
				element: " .awesomplete > [data-fieldname='supplier']",
				popover: {
					title: "1.Saisir le fournisseur",
					description: `
						<p>Par exemple, tapez "Allied Radio".</p>
					`,
					position: 'left-top',
				}
			},
			{
				element: ".row.layout-main > div.col.layout-main-section-wrapper > div.layout-main-section > div:nth-child(2) > div > div > div.form-page > div:nth-child(9) > div > div > form > div:nth-child(3) > div.form-grid > div.grid-body > div.rows > div > div > div:nth-child(3)",
				popover: {
					title: "2.Saisir l'article...",
					description: `
						<p>Par exemple, tapez "wii remote".</p>
					`,
					position: 'left-top',
				}
			},
			{
				element: ".row.layout-main > div.col.layout-main-section-wrapper > div.layout-main-section > div:nth-child(2) > div > div > div.form-page > div:nth-child(9) > div > div > form > div:nth-child(3) > div.form-grid > div.grid-body > div.rows > div > div > div:nth-child(4)",
				popover: {
					title: "3. ...et la quantité",
					description: `
						<p>Verifiez que le prix unitaire est correcte</p>
					`,
					position: 'left-top',
				}
			},
			{
				element: ".row.layout-main > div.col.layout-main-section-wrapper > div.layout-main-section > div:nth-child(2) > div > div > div.form-page > div:nth-child(14)",
				popover: {
					title: "Vérifiez que la TVA est correcte",
					description: `
						<p>Sur ce site, un taux de 20% est appliqué aux achats par défaut. La TVA est entièrement paramètrable et le taux peut être appliqué selon le fournisseur, l'article. Elle peut être entièrement automatisé via des règles de taxation</p>
					`,
					position: 'left-top',
				}
			},
			{
				element: ".standard-actions.flex > button.btn.btn-primary.btn-sm.primary-action",
				popover: {
					title: "Enregistrez votre saisie",
					description: `
						<p>Avec le raccourci ctrl+s ou en cliquant sur 'enregistrer' dans le coin supérieur droit</p>
						<p>La facture s'enregistre en brouillon. Vous aurez ensuite la possibilité de la valider.</p>
					`,
					position: 'left-top',
				}
			},
		]
	}
	return highlights
}

export default custom_workspace_highlight = [
	{
		element: ".dashboard-widget-box",
		popover: {
			title: 'Les graphiques',
			description: `
				<p>Thierry a choisi quelques graphiques pour obtenir quelques informations comptables.</p>
			`,
			position: 'bottom-center',
		}
	},
	{
		element: ".shortcut-widget-box",
		popover: {
			title: 'Les raccourcis',
			description: `
				<p>Les raccourcis permettent d'accéder rapidement à une fonctionnalité du logiciel.</p>
				<p>Ici Thierry a paramétré des raccourcis permettant de créer de nouveaux documents en un clic</p>
			`,
			position: 'top-left',
		}
	},
	{
		element: ".widget.quick-list-widget-box",
		popover: {
			title: 'Listes rapides',
			description: `
				<p>La version 3 de dokos permet l'ajout de "listes rapides". Celles-ci permettent d'afficher une liste simplifiée d'un type de document.</p>
				<p>Les listes rapides peuvent être filtrées.</p>
				<p>Thierry peut afficher les factures en retard, les devis ouverts etc.</p>
			`,
			position: 'top-center',
		},
		onNext: () => {
			frappe.utils.scroll_to(0)
		}
	},
	{
		element: ".standard-sidebar-label",
		popover: {
			title: 'Espace public',
			description: `
				<p>Si l'espace de travail n'est pas défini comme "public" à la création, il apparaît ici et sera visible uniquement pour Thierry autrement il sera listé ci-dessous</p>
			`,
			position: 'bottom-left',
		},
		onNext: () => {
			frappe.utils.scroll_to(0)
		}
	},
	{
		element: ".standard-actions",
		popover: {
			title: 'Modifier',
			description: `
				<p>L'espace de travail peut être modifié ici</p>
			`,
			position: 'bottom-right',
		}
	},
]

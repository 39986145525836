export default [
	{
		element: "[data-page-route='List/Item Booking/Calendar/default'] .fc-button-group",
		popover: {
			title: "Modifiez l'affichage de votre calendrier",
			description: `
				<p>Cliquez sur le bouton "Semaine" ou "Jour" pour ne voir que la période la plus récente.</p>
			`,
			position: 'bottom-center',
		}
	},
	{
		element: "[data-page-route='List/Item Booking/Calendar/default'] .standard-filter-section > [data-fieldname='item']",
		popover: {
			title: "Ajoutez des filtres",
			description: `
				<p>Vous pouvez utiliser les filtres disponibles pour voir le calendrier d'un article en particulier ou bien uniquement les réservations non confirmées par exemple.</p>
			`,
			position: 'left-top',
		}
	},
	{
		element: "[data-page-route='List/Item Booking/Calendar/default'] .standard-actions",
		popover: {
			title: "Un menu et des actions sont à votre disposition",
			description: `
				<p>Le menu vous permet d'accéder aux autres calendriers disponibles dans Dokos ou d'ouvrir/fermer la barre latérale.</p>
			`,
			position: 'left-top',
		}
	},
	{
		element: "[data-page-route='List/Item Booking/Calendar/default'] .custom-btn-group",
		popover: {
			title: "Changez de vue",
			description: `
				<p>Les réservations d'article, comme tout type de document dans Dokos, dispose de différentes vues pour permettre des usages complémentaires.</p>
				<p>En cliquant sur ce bouton, vous pouvez basculer sur une autre vue.</p>
				<p>Par exemple, la vue "Liste" sera plus pertinente pour voir toutes vos réservations non confirmées.</p>
			`,
			position: 'left-top',
		}
	}
]

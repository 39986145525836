import app from './content/app'
import venue from './content/venue'
import item_booking from './content/item_booking'
import custom_workspace from './content/custom_workspace'
import selling from './content/selling'
import buying from './content/buying'
import sales_invoice from './content/sales_invoice'
import purchase_invoice from './content/purchase_invoice'

/*
Enregistrez vos slides dans le dossier content, puis mappez la route à laquelle elles doivent apparaître ici.
*/

const data = (route, useCase) => {
	let tours = {}
	// Slides communes aux deux cas d'usages
	Object.assign(tours, {
		app: app(useCase),
		'private,Tableau de bord-thierry@dokos.io': custom_workspace(useCase),
		Selling: selling(useCase),
		Buying: buying(useCase),
		'Purchase Invoice,new-purchase-invoice-1': purchase_invoice(useCase),
		'Sales Invoice,List': sales_invoice(useCase)
	})

	// Slides spécifiques à MappeMonde
	if (useCase == "MappeMonde") {
		Object.assign(tours, {
			Venue: venue(useCase),
			'Item Booking,Calendar': item_booking(useCase),
		})
	}

	return tours[route] || {}
}


export default data;

import slides from './slides/index'

frappe.provide("demo.ui")

$(document).bind('page-change', function() {
	set_tour();
});

frappe.router.on("change", () => {
	set_tour();
});

const set_tour = () => {
	if (frappe.boot.lang == 'fr' && (!demo.ui.TourDialog || !demo.ui.TourDialog.is_visible)) {
		/* Returns route as:
			- app
			- venue
			- 'Item Booking,Calendar'
			- 'Item Booking,List'
		*/
		let route = frappe.get_route()&&frappe.get_route().length&&frappe.get_route()[0] ? frappe.get_route().slice(1, 3) : "app"
		route = route.length == 1 && route !== "app" ? route[0] : route.toString()
		const useCase = frappe.defaults.get_user_default("Company")
		// Curent use cases are MappeMonde / Dokompany

		demo.ui.TourDialog = tour_dialog(route, useCase)
	}
}

const tour_dialog = (route, useCase) => {
	const dialog_content = slides(route, useCase)
	if (Object.keys(dialog_content).length) {
		const dialog  = new frappe.ui.Dialog({
			title: dialog_content.title,
			size: "large",
			fields: [
				{
					"fieldtype": "HTML",
					"fieldname": "content"
				}
			],
			primary_action_label: dialog_content.primary_action_label,
			primary_action: dialog_content.primary_action,
			secondary_action_label: dialog_content.secondary_action_label,
			secondary_action: dialog_content.secondary_action,
			onhide: () => {
				dialog.is_visible = false;
			}
		});
		dialog.get_field("content").wrapper.innerHTML = dialog_content.content
		dialog.show();

		return dialog
	}
}

export default slide = (useCase) => {
	return {
		title:  __("Factures de vente"),
		content: `<div style="font-size: var(--text-lg);">
			<div>
				<img src="/assets/demo/images/pexels-kindel-media-7651555_sales-invoice.jpg"
					style="max-width:70%;height:auto;display:block;margin-left:auto;margin-right:auto">
			</div>
			<div class="mt-4">
				<p>En accédant aux factures de vente, Thierry peut avoir une vue détaillée des prestations fournies.</p>
				<p>Sur dokos, un document peut avoir différents status. Une facture peut par exemple être à l'état de "brouillon", puis "impayé", "en retard", "payé" etc.</p>
		</div>`,
		primary_action_label: "Explorer la liste des factures",
		primary_action: () => {
			demo.ui.TourDialog.hide()
			setTimeout(() => {
				const tour = new demo.ui.Tour({ slides: "sales_invoice_highlight" });
				tour.show();
			}, 1000)
		}
	}
}

